import React from 'react';


const Banner = () => {
    return (    
<div className='w-full'>
    <img src="img/2.jpg" className='w-full' alt="" />
</div>
     
    );
}

export default Banner;
